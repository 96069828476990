import { IconsTheme } from '@wrisk/ui-components'

import account, { ReactComponent as Account } from './assets/icons/account.svg'
import back, { ReactComponent as Back } from './assets/icons/back.svg'
import bin, { ReactComponent as Bin } from './assets/icons/bin.svg'
import call, { ReactComponent as Call } from './assets/icons/call.svg'
import chat, { ReactComponent as Chat } from './assets/icons/chat.svg'
import checkboxOff, { ReactComponent as CheckboxOff } from './assets/icons/check-off.svg'
import checkboxOn, { ReactComponent as CheckboxOn } from './assets/icons/check-on.svg'
import chevronDown, {
  ReactComponent as ChevronDown,
} from './assets/icons/chevron-down.svg'
import chevronRight, {
  ReactComponent as ChevronRight,
} from './assets/icons/chevron-right.svg'
import chevronUp, { ReactComponent as ChevronUp } from './assets/icons/chevron-up.svg'
import cross, { ReactComponent as Cross } from './assets/icons/cross.svg'
import document, { ReactComponent as Document } from './assets/icons/document.svg'
import edit, { ReactComponent as Edit } from './assets/icons/edit.svg'
import email, { ReactComponent as Email } from './assets/icons/email.svg'
import google, { ReactComponent as Google } from './assets/icons/google.svg'
import help, { ReactComponent as Help } from './assets/icons/help.svg'
import info, { ReactComponent as Info } from './assets/icons/info.svg'
import loading, { ReactComponent as Loading } from './assets/icons/loading.svg'
import loadingDark, {
  ReactComponent as LoadingDark,
} from './assets/icons/loading-dark.svg'
import media, { ReactComponent as Media } from './assets/icons/media.svg'
import menu, { ReactComponent as Menu } from './assets/icons/menu.svg'
import minus, { ReactComponent as Minus } from './assets/icons/minus.svg'
import more, { ReactComponent as More } from './assets/icons/more.svg'
import newTab, { ReactComponent as NewTab } from './assets/icons/newtab.svg'
import plus, { ReactComponent as Plus } from './assets/icons/plus.svg'
import policy, { ReactComponent as Policy } from './assets/icons/policy.svg'
import poweredByWrisk, {
  ReactComponent as PoweredByWrisk,
} from './assets/icons/powered-by-wrisk.svg'
import progressActive, {
  ReactComponent as ProgressActive,
} from './assets/icons/progress-active.svg'
import progressInactive, {
  ReactComponent as ProgressInactive,
} from './assets/icons/progress-inactive.svg'
import progressSeparator, {
  ReactComponent as ProgressSeparator,
} from './assets/icons/progress-separator.svg'
import radioButtonOff, {
  ReactComponent as RadioButtonOff,
} from './assets/icons/radio-button-off.svg'
import radioButtonOn, {
  ReactComponent as RadioButtonOn,
} from './assets/icons/radio-button-on.svg'
import search, { ReactComponent as Search } from './assets/icons/search.svg'
import tick, { ReactComponent as Tick } from './assets/icons/tick.svg'
import time, { ReactComponent as Time } from './assets/icons/time.svg'
import trustPilot, { ReactComponent as TrustPilot } from './assets/icons/trustPilot.svg'
import upload, { ReactComponent as Upload } from './assets/icons/upload.svg'
import warning, { ReactComponent as Warning } from './assets/icons/warning.svg'

const icons: IconsTheme = {
  account: { url: account, component: Account },
  back: { url: back, component: Back },
  chat: { url: chat, component: Chat },
  checkboxOff: { url: checkboxOff, component: CheckboxOff },
  checkboxOn: { url: checkboxOn, component: CheckboxOn },
  chevronDown: { url: chevronDown, component: ChevronDown },
  chevronRight: { url: chevronRight, component: ChevronRight },
  chevronUp: { url: chevronUp, component: ChevronUp },
  cross: { url: cross, component: Cross },
  document: { url: document, component: Document },
  edit: { url: edit, component: Edit },
  google: { url: google, component: Google },
  help: { url: help, component: Help },
  info: { url: info, component: Info },
  loading: { url: loading, component: Loading },
  loadingDark: { url: loadingDark, component: LoadingDark },
  menu: { url: menu, component: Menu },
  minus: { url: minus, component: Minus },
  newTab: { url: newTab, component: NewTab },
  plus: { url: plus, component: Plus },
  policy: { url: policy, component: Policy },
  poweredByWrisk: { url: poweredByWrisk, component: PoweredByWrisk },
  radioButtonOff: { url: radioButtonOff, component: RadioButtonOff },
  radioButtonOn: { url: radioButtonOn, component: RadioButtonOn },
  search: { url: search, component: Search },
  tick: { url: tick, component: Tick },
  time: { url: time, component: Time },
  warning: { url: warning, component: Warning },
  more: { url: more, component: More },
  call: { url: call, component: Call },
  email: { url: email, component: Email },
  progressActive: { url: progressActive, component: ProgressActive },
  progressInactive: { url: progressInactive, component: ProgressInactive },
  progressSeparator: { url: progressSeparator, component: ProgressSeparator },
  bin: { url: bin, component: Bin },
  media: { url: media, component: Media },
  trustPilot: { url: trustPilot, component: TrustPilot },
  upload: { url: upload, component: Upload },
}

export default icons
